import logo from "../AWS_Infrastructure.svg";
import * as React from "react";

export const Header = () => {
  return (
    <div className={"awsscm-infra-logo-header"}>
      <img src={logo} alt={"AWS infrastructure logo"}/>
      <p>Supply Chain</p>
    </div>
  )
}